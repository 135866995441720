<script setup lang="ts">
  import { useQueryClient } from '@tanstack/vue-query'
  const { companyId } = storeToRefs(useStoreApp())
  const { extractTypography } = useBrandingTypography()
  const { company } = storeToRefs(useStoreCompany())

  const { data: brandings } = useStoreBranding().fetchBrandings(companyId.value)

  const headerBranding = computed(() => {
    const brandingHeader = brandings.value?.branding_header

    if (!brandingHeader) return

    const { id, section_color, img_mobile, img_desktop, layout } = brandingHeader

    return {
      id,
      backgroundColor: section_color,
      backgroundImageDesktopUrl: img_desktop,
      backgroundImageMobileUrl: img_mobile,
      sectionDescription: extractTypography('branding_header', 'description', 'primary'),
      sectionTitle: extractTypography('branding_header', 'title', 'secondary'),
      contentAlignment: getContentAlignment(layout)
    }
  })

  const postNewHeightMessage = async () => {
    const { body, documentElement } = document

    const countModalOnScreen = document.querySelectorAll('.lds-modal__container').length

    useStoreApp().domModalsCount = countModalOnScreen

    await nextTick()

    if (countModalOnScreen === 0) {
      let height = Math.max(body.scrollHeight, body.offsetHeight, documentElement.offsetHeight)
      const header = document.getElementsByClassName('section-header')[0]
      const sections = document.getElementById('container-sections')
      if (header && sections) {
        height = header.scrollHeight + Math.max(sections.scrollHeight, sections.offsetHeight)
      }

      window.parent.postMessage({ height, isWeWiink: true }, '*')
    } else {
      const allModals = document.querySelectorAll('.lds-modal__mask')
      const count = allModals.length
      if (count > 1 && useStoreApp().isModalFullPage) {
        for (let i = 0; i < count - 1; i++) {
          const modal = allModals[i] as HTMLElement
          modal.style.display = 'none'
        }
      } else if (count === 1) {
        const modal = allModals[0] as HTMLElement
        modal.style.display = 'inherit'
      }
      const lastModalOnScreen = document.querySelectorAll('.lds-modal__container')[count - 1] as HTMLElement
      await nextTick()

      const height = Math.max(lastModalOnScreen.scrollHeight, lastModalOnScreen.offsetHeight)
      if ((!useStoreApp().isModalFullPage || count > 1) && useStoreApp().isMobile) {
        window.parent.postMessage({ fullheight: true, isWeWiink: true }, '*')
      } else {
        window.parent.postMessage({ height, isWeWiink: true }, '*')
      }
    }
    setAppWidth()
  }

  let readyToScroll = false
  const queryClient = useQueryClient()
  const scrollToAnchorWhenReady = () => {
    setTimeout(() => {
      if (queryClient.isFetching() > 0 && !document.URL.includes('shopify/embedded')) {
        // API calls started to populate the page
        readyToScroll = true
      }
      if (queryClient.isFetching() === 0 && readyToScroll) {
        // all API calls finished, page has final size
        scrollToAnchor()
      } else {
        scrollToAnchorWhenReady() // Page not ready yet to scroll, retry
      }
    }, 100)
  }

  const scrollToAnchor = () => {
    let hash: string | undefined | null
    if (useEmbedded().isEmbedded.value) {
      hash = new URL(document.URL).searchParams.get('anchorHash')
    } else {
      return
    }

    const isModalAnchor = hash?.includes('-')
    // if url hash is like #challenge-1 navigate to section as well as open the modal
    if (isModalAnchor) {
      hash = hash!.split('-')[0].replace('#', '') // #challenge-1 => challenge (anchor id)
    }

    const item = document.getElementById(hash || '')
    if (item)
      nextTick(() => {
        // do not scroll to anchor if it is a modal anchor in a mobile context
        if (useEmbedded().isEmbedded.value && useStoreApp().isMobile && !isModalAnchor) {
          const top = item.getBoundingClientRect().top + useStoreStore().offset
          window.parent.postMessage({ scrollTo: { top, behavior: 'smooth' }, isWeWiink: true }, '*')
          return
        }

        item.scrollIntoView({ behavior: 'smooth' })
      })
  }

  const observer = ref<MutationObserver | null>(null)
  watch(brandings, (brandings) => {
    if (brandings) {
      setCompanyFonts(brandings.branding_general)
      scrollToAnchorWhenReady()
    }
  })

  const observerResize = ref<ResizeObserver | null>(null)

  const setAppWidth = () => {
    const { body, documentElement } = document
    useStoreApp().appWidth = Math.max(body.scrollWidth, body.offsetWidth, documentElement.offsetWidth)
  }

  onMounted(() => {
    observer.value = new MutationObserver(postNewHeightMessage)
    observer.value.observe(document.body, { childList: true, subtree: true })
    observerResize.value = new ResizeObserver(setAppWidth)
    observerResize.value.observe(document.body)
  })

  onUnmounted(() => {
    observer.value?.disconnect()
    observerResize.value?.disconnect()
  })
</script>

<template>
  <div
    v-if="headerBranding"
    v-show="useStoreApp().domModalsCount === 0"
    class="default-layout"
  >
    <SectionHeader
      :id="headerBranding.id"
      class="section-header"
      :section-title="headerBranding.sectionTitle"
      :section-description="headerBranding.sectionDescription"
      :background-color="headerBranding.backgroundColor"
      :background-image-desktop-url="headerBranding.backgroundImageDesktopUrl"
      :background-image-mobile-url="headerBranding.backgroundImageMobileUrl"
      :content-alignment="headerBranding.contentAlignment"
      :powered-by-text="$t('SectionHeader.poweredBy')"
      powered-by-link="https://loyoly.io/"
      :show-powered-by="company?.settings.features.white_label"
    >
      <template #title>
        {{ headerBranding.sectionTitle.text }}
      </template>
      <template #description>
        {{ headerBranding.sectionDescription.text }}
      </template>
      <div id="header-buttons" />
    </SectionHeader>
    <slot />
  </div>
</template>

<style scoped>
  .default-layout {
    width: 100%;
    box-sizing: border-box;

    #header-buttons {
      display: flex;
      gap: 10px;
      flex-direction: column;

      @media (min-width: 768px) {
        gap: 20px;
        flex-direction: row;
      }
    }
  }
</style>
